














































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Location } from 'vue-router';
import ContactDetailsDialog from '../../contact/components/ContactDetailsDialog.vue';
import ContactPersonRow from '../../contact/components/ContactPersonRow.vue';
import { Contact, ContactPerson } from '../types';
import EditContactPersonDialog from '@/modules/contact/components/EditContactPersonDialog.vue';
import RegisterContactPersonDialog from '@/modules/contact/components/RegisterContactPersonDialog.vue';

@Component({
  components: {
    RegisterContactPersonDialog,
    EditContactPersonDialog,
    ContactDetailsDialog,
    ContactPersonRow
  }
})
export default class ContactPersonsDialog extends Vue {
  @Prop({ type: Object, required: true }) contact!: Contact;
  @Prop({ type: String, default: 'Ansprechpartner' }) label!: string;
  @Prop({ type: String }) icon?: string;

  contactDetails: ContactPerson | null = null;
  updateContactPerson: ContactPerson | null = null;
  dialog: boolean = false;

  generateLetterUrl(contactPerson: ContactPerson): Location | undefined {
    return {
      name: 'correspondence-letter-builder',
      query: {
        contact: contactPerson.contactId,
        receiverType: this.contact.types[0],
        contactPerson: contactPerson.id,
        referrer: this.$route.fullPath
      }
    };
  }

  generateEmailUrl(contactPerson: ContactPerson) {
    if (!contactPerson.email) {
      return;
    }

    return {
      name: 'correspondence-email-builder',
      query: {
        contact: contactPerson.contactId,
        receiverTypes: this.contact.types,
        contactPerson: contactPerson.id,
        referrer: this.$route.fullPath
      }
    };
  }
}
