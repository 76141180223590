



















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class EmailRow extends Vue {
  @Prop({ type: String }) value?: string;
  @Prop({ type: String }) labelInfo?: string;
  @Prop({ type: String }) icon?: string;
  @Prop({ type: Boolean, default: false }) tooltip!: boolean;
  @Prop({ type: String, required: true }) label!: string;
  @Prop({ type: Boolean, default: true }) translated!: boolean;
  @Prop({ type: Boolean, default: true }) actions!: boolean;
  @Prop({ default: 'indigo' }) iconColor!: string;
}
