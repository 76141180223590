import uuid from 'uuid/v4';
import { Contact, ContactPerson, Type, WriteContact, WriteContactPerson } from '@/modules/contact/types';
import { WriteCourt } from '@/modules/court/types';
import { WriteBank } from '@/modules/bank/types';

export const createContactPerson = (contact_id: string, contact_person_id: string = uuid()): WriteContactPerson => ({
  contact_id,
  contact_person_id,
  name: '',
  email: null,
  phone_number: null,
  fax_number: null,
  mobile_number: null
});

export const createContact = (types: Type[], contact_id: string = uuid()): WriteContact => ({
  contact_id,
  additional: '',
  name: '',
  street: '',
  zip_code: '',
  city: '',
  note: '',
  phone_number: '',
  types
});

export const createBank = (contact_id: string = uuid()): WriteContact & WriteBank => ({
  contact_id,
  bank_id: contact_id,
  bank_code: '',
  bic: '',
  additional: '',
  name: '',
  street: '',
  zip_code: '',
  city: '',
  note: '',
  phone_number: '',
  types: ['bank']
});

export const createCourt = (contact_id: string = uuid()): WriteContact & WriteCourt => ({
  contact_id,
  court_id: contact_id,
  additional: 'Betreuungsgericht',
  name: '',
  street: '',
  zip_code: '',
  city: '',
  note: '',
  phone_number: '',
  types: ['mental_health_court']
});

export const MapStateToWriteContactPerson = (contactPerson: ContactPerson): WriteContactPerson => ({
  contact_id: contactPerson.contactId,
  contact_person_id: contactPerson.id,
  name: contactPerson.name,
  email: contactPerson.email,
  phone_number: contactPerson.phoneNumber,
  fax_number: contactPerson.faxNumber,
  mobile_number: contactPerson.mobileNumber
});

export const MapWriteContactToWriteCourt = (address: WriteContact): WriteCourt => ({
  court_id: address.contact_id,
  name: address.name,
  additional: address.additional || '',
  street: address.street || '',
  zip_code: address.zip_code || '',
  city: address.city || '',
});

export const MapContactToWriteContact = (address: Contact): WriteContact => ({
  contact_id: address.id,
  additional: address.additional || '',
  name: address.name,
  street: address.street || '',
  zip_code: address.zipCode || '',
  city: address.city || '',
  phone_number: address.phoneNumber || '',
  note: address.note || '',
  types: address.types
});
