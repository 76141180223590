









































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ContactPerson } from '../types';
import PhoneRow from '@/components/PhoneRow.vue';
import EmailRow from '@/components/form/EmailRow.vue';

@Component({
  components: { EmailRow, PhoneRow }
})
export default class ContactDetailsDialog extends Vue {
  @Prop({ type: Object }) value?: ContactPerson;

  dialog: boolean = false;

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value !== false) return;

    setTimeout(() => {
      this.dialog = false;
      this.$emit('input', null);
    }, 200);
  }

  @Watch('value')
  watchLetter(contactPerson: ContactPerson | null) {
    if (!contactPerson) return;

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }
}
