































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ContactPerson } from '../types';
import { Location } from 'vue-router';

@Component({})
export default class ContactPersonRow extends Vue {
  @Prop({ type: Object, required: true }) contact!: ContactPerson;
  @Prop({ type: Boolean, default: false }) actions!: boolean;
  @Prop({ type: Object }) toLetterBuilder?: Location;
  @Prop({ type: Object }) toEmailBuilder?: Location;
  @Prop({ type: String }) icon?: string;
  @Prop({ default: 'indigo' }) iconColor!: string;
}
