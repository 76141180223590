







































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { REGISTER_CONTACT_PERSON } from '@/modules/contact/store';
import { WriteContactPerson } from '@/modules/contact/types';
import { ApiResponse } from '@/components/types';
import { createContactPerson } from '@/modules/contact/model';

const Contact = namespace('contact');

@Component({})
export default class RegisterContactPersonDialog extends Vue {
  @Prop({ type: String }) contact!: string;

  @Contact.Action(REGISTER_CONTACT_PERSON) registerContactPerson!: (params: WriteContactPerson) => Promise<ApiResponse>;

  dialog: boolean = false;
  error: any = null;
  contactPerson: WriteContactPerson = createContactPerson(this.contact);

  @Watch('dialog')
  onDialog(dialog: boolean) {
    if (dialog) return;

    setTimeout(() => {
      this.contactPerson = createContactPerson(this.contact);
      this.error = null;
      // @ts-ignore
      this.$refs.form.reset();
    });
  }

  async save() {
    this.error = null;

    return await this.registerContactPerson(this.contactPerson);
  }
}
