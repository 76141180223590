




import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { contactTypes, departmentTypes } from '../helper';
import { Contact } from '../types';

const UpdateCourtDialog = () => import('@/modules/contact/components/UpdateContactDialog/UpdateCourtDialog.vue');
const UpdateBankDialog = () => import('@/modules/contact/components/UpdateContactDialog/UpdateBankDialog.vue');
const UpdateHealthInsuranceDialog = () => import('@/modules/contact/components/UpdateContactDialog/UpdateHealthInsuranceDialog.vue');
const ChangeDepartmentDialog = () => import('@/modules/contact/components/UpdateContactDialog/ChangeDepartmentDialog.vue');
const ChangeContactDialog = () => import('@/modules/contact/components/UpdateContactDialog/ChangeContactDialog.vue');

@Component({
  components: {
    UpdateCourtDialog,
    UpdateBankDialog,
    UpdateHealthInsuranceDialog,
    ChangeDepartmentDialog,
    ChangeContactDialog
  }
})
export default class UpdateContactDialog extends Vue {
  @Prop({ type: Object }) contact?: Contact;

  dialog: boolean = false;

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value) return;

    setTimeout(() => {
      this.$emit('update:contact', null);
    }, 200);
  }

  @Watch('contact')
  watchContact(contact: Contact) {
    if (!contact) return;

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  get contactToDialog(): { [type: string]: string } {
    const mapping: { [type: string]: string } = {
      mental_health_court: 'update-court-dialog',
      bank: 'update-bank-dialog',
      health_insurance: 'update-health-insurance-dialog'
    };

    contactTypes().forEach((contact) => {
      mapping[contact.value] = 'change-contact-dialog';
    });

    departmentTypes().forEach((department) => {
      mapping[department.value] = 'change-department-dialog';
    });

    return mapping;
  }

  get dialogType(): string {
    if (!this.contact) return 'span';

    return this.contactToDialog[this.contact.types[0]] || 'span';
  }
}
