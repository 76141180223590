




















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { EDIT_CONTACT_PERSON, REMOVE_CONTACT_PERSON } from '../store';
import { ContactPerson, WriteContactPerson } from '../types';
import { ApiResponse } from '@/components/types';
import { MapStateToWriteContactPerson } from '@/modules/contact/model';

const ContactPerson = namespace('contact');

@Component({})
export default class EditContactPersonDialog extends Vue {
  @Prop({ type: Object }) contactPerson!: ContactPerson | null;

  @ContactPerson.Action(EDIT_CONTACT_PERSON) editContactPerson!: (params: WriteContactPerson) => Promise<ApiResponse>;
  @ContactPerson.Action(REMOVE_CONTACT_PERSON) removeContactPerson!: (params: WriteContactPerson) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;
  value: WriteContactPerson | null = null;

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value) return;

    setTimeout(() => {
      this.error = null;
      this.value = null;
      this.$emit('update:contactPerson', null);
    }, 200);
  }

  @Watch('contactPerson')
  watchPerson(contactPerson: ContactPerson) {
    if (!contactPerson) return;

    this.value = MapStateToWriteContactPerson(contactPerson);

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  async save() {
    this.error = null;

    if (!this.value) return {};

    return this.editContactPerson(this.value);
  }

  async remove() {
    this.error = null;

    if (!this.value) return {};

    return this.removeContactPerson(this.value);
  }
}
